/* CSS for NutrifyMyDiet */
body{
    max-width: 100%;
    overflow-x: hidden;
}

.no-padding {
    padding: 0px !important;
}

.no-padding-right {
    padding-right: 0px !important;
}

.no-shadow.w3-btn:hover{
    box-shadow: none;
    color: #2196F3 !important;
}

.nut-option-btn-padding {
    padding:2px 2px 2px 0px;
    outline:0;
}
.no-click{
    cursor: not-allowed !important;
}

.success-message{
    background-color: #bdf9dc !important;
}
.error-message{
    background-color: #ffcccc !important;
}

.logo-upload-note{
    color: blue;
}
.sideBar {
    a {
        text-decoration: none;
    }
}

.logo-choose{
    top: 177px;
}

.logo-img{
    width: auto;
    object-fit: contain;
    height: 200px;
    // min-width: 335px;
    // max-width: 335px;
    margin-bottom: 2px;
    width: 100%;
} 

.logo-label a{
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
    background-color:#ffffff;
    padding: 5px 2px 2px;
    color: #000;
    cursor: pointer;
}

.no-decoration{
    text-decoration: none;
    cursor: pointer;
}

.no-decoration:hover {
    color: #1468a0;
}

.nutrify-btn{
    background-color: #1779ba;
    color: #fefefe;
}
.nutrify-btn:hover{
    background-color: #14679e !important;
    color: #fefefe !important;
}

.w3-ul .li-no-border {
    padding: 0.5;
    border-bottom: none; 
}

.check-small,.radio-small{
    width: 18px;
    height: 18px;
}

.checkbox-space{
    margin-right: 5px !important;
}

.btn-space{
    padding: 0.5em 0.8em;
    margin-left: 2px;
    margin-right: 2px;
}

.client-unique-id, .dietician-email{    
    padding-left: 8px;
    padding-right: 8px;
}

.params-view-btn{
    margin-top: -15px;
    right:15px;
}

.text-dotdot { 
    white-space: nowrap !important; 
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:100%;
    padding-right:10px !important;
}

.logo-position{
    position: relative;
    display: inline-block;
}
/*
.email-section{
    position: absolute;
    display: -ms-flexbox;
    -ms-flex-direction: column;
    display: flex;
    flex-direction: column;
    top: 103px;
    right: 400px;
    width: 325px !important;
}
*/

.dietary-save-reminder{
    margin-top: 10px;
    margin-bottom: 0;
    font-size: .75rem;
    font-weight: 600;
    color: #cc4b37;
}

.error-red {
    margin-top: 0;
    margin-left: .5rem;
    margin-bottom: 0;
    font-size: .75rem;
    font-weight: 700;
    color: #cc4b37;
}

.select-padding{
    padding: 5px  !important;
}

.select-error .Select-control {
    border-color:#cc4b37;
}
.checkbox-error {
    color:#cc4b37;
}
.text-top-padding{
    padding-top: 10px;
}

.nutritionist-select{
    height:35px !important;
}

.w3-button {
    border-radius: 4px;
}
button.supplement-cls, button.new-meal-cls{
    margin-left: 0px;
    margin-top: .5rem;
}

.add-meal-note{
    color:blue;
}

button.supplement-save, button.new-meal-save{
    margin-left: 10px;
    margin-top: .5rem;
}

button.supplement-add{
    margin-left: 0px;
}


.textarea-wrap{
    white-space: pre-wrap;
}

.day-descr-form{
    background-color: white;
    margin-bottom: 0px;
    z-index: 1000;
}

.edit-desc-spacing{
    margin-top: 5px;
    margin-bottom: 5px;
    z-index: 1100;
}

.edit-desc-button{
    margin-right: 5px;
}

.day-descr-edit{
    padding: 5px;
    color: grey;
    &:hover {
        background-color: blue; 
    }
}


table.supplement-table,table.mealtime-survey,
table.sports-survey {
    thead  > tr > th {
    font-size:14px;
    font-weight:bold;
    }
    tbody  > tr {
     td {
        font-size: 12px;  
     }
     td.link-col,.desc-col {
        word-break: break-word;
        position:relative;

        .supp-delete-btn, .newMeal-delete-btn,.survey-workout-delete,.survey-workout-edit {
            position:absolute;
            right:0px;
            top:0px;
            padding:4px;
            display:none;   
            background:rgb(255,120,120);
            color:white; 
        }
        .survey-workout-edit{
            right:23px;
            background:rgb(33,150,243);
        } 
    }
        &:hover {
            td {
             .supp-delete-btn, .newMeal-delete-btn,.survey-workout-delete,.survey-workout-edit{
                display:block;
             }
            }
        }
    }

}
.settings-delete{
    color: red;
    padding: 5px;
    &:hover {
        display:block;
        background-color: #e60000;
    }
}
.settings-tick{
    color:green; 
    padding: 10px;
    &:hover {
        display:block;
    }
}



.no-data-text { 
    text-align: center;
    padding: 80px 0px;
    font-size: 1.1em;
    background: #F2f2f2;
    // border-radius: 10px;
    margin-top: 10px;
    font-style: italic;
    line-height: 1.6em;
    font-weight: bold;
    color: #444;
}
.survey-bold{
    font-weight:bold;
}

.settings-border{
    border: solid 1px #EDEDED;
    margin: 10px;
    padding:10px;
    border-radius: 4px;
}

.settings-margin{
    margin-left: -15px;
    margin-right: -15px;
}
.container-sizing-small{
    padding: 8px;
    background: #e6e6e6;
    .question-view{

        border-top:solid 2px #EEE;
        .w3-row {
            border: none;
        }

        &.m8{
            width:100%;
            max-width: 100%;
            flex:0 0 100%;
        }
        &.m4{
            width:100%;
            max-width: 100%;
            flex:0 0 100%;
        }
        &.m6{
            width:100%;
            max-width: 100%;
            flex:0 0 100%;
        }
    }
}
.n5y-btn-list {
    .w3-button {
        margin-right:4px;
    }
}
.survey-margin{
   margin-left: 1rem; 
}

.left-padding {
    padding-left:10px;
}
 .button-icon{
    margin-right:10px;
    padding-right:6px;
    border-right:solid 1px white;
 }

.Select-menu-outer {
    z-index: 10000;
}

button:hover , button:active {
    outline:0px;
}

.copy-plan-box{
    padding: 16px
}

.day-top-content{
    font-size: 14px;
    text-align: right;
    margin-top: 10px;
}
.day-meal-column {
    width: 25%;
    min-width: 250px;
    display: inline-block;
    margin: 0px 6px;
    border: solid 1px #c3c3c3;
    border-radius: 6px;
    min-height: 1000px;
    .day-meal-column-header {
        background: #f3f3f3;
        padding: 4px;
        text-align: center;
        font-size: 18px;
        border-radius: 6px 6px 0px 0px;
        font-weight: bold;
    }
    .day-meal-placeholder{
        .label{
            display: none;
        }

        &:hover .label , &.active .label{
            display: inline-block;
            margin: 0px 10px;
            border-radius: 2px;
            cursor: pointer;
        }
        font-size: 14px;
        color: #2E4053;
        border-bottom: solid 1px #d5d5d5;
        min-height: 28px;
        line-height: 28px;
        padding-left:4px;

    }
}

.meal-table {
    border:none;
    position: relative;
    thead{
        position:absolute;
        background:#dee0e1;
        padding:8px 0px 4px 0px;
        border: none;
        display: block
    }
    tbody{
        border:none;
        display:block;
        padding-top:130px;
    }
    width:0px;
    border-radius: 0;
    border-collapse: separate;
    border-spacing: 10px 0px;
    &.print-mode {
        tr.meal-day-row-empty{
            display:none;
        }
        td.meal-table-cell {
            .button-groups {
                display:none;
            }
        }
    }
}
.meal-table-cell {
    &.top {
        border-top:solid 1px #ededed;
        border-bottom:solid 2px #ededed;
        border-radius: 6px 6px 0px 0px;
        .day-meal-column-header {
            background: #f3f3f3;
            padding: 4px;
            text-align: center;
            height: 36px;
        }
    }


    border:none;
    vertical-align: top;
    width:25vw;
    min-width: 300px;
    padding:0px;
    background: white;
    margin:0px;
    display:table-cell;
    border-right: solid 1px #EDEDED;
    border-left:solid 1px #EDEDED;

    .day-meal-placeholder{
        .label{
            display: none;
        }

        &:hover .label , &.active .label{
            display: inline-block;
            margin: 0px 10px;
            border-radius: 2px;
            cursor: pointer;
        }
        font-size: 14px;
        color: #2E4053;
        border-top: solid 1px #d5d5d5;
        min-height: 28px;
        line-height: 28px;
        padding-left:4px;
    }

}

.delete-plan-button{
    background: red; 
    color: white;
    float: right;
}

.day-delete-button{
    color: black !important;
}

.txt-conflict {
    background:red !important;
    font-weight:bold;
}

.day-meal-card {
    
    &.conflict{
        background:#EDEDED !important;
        .top-header{
            background:red;
        }
    }
    .txt-conflict {
        background:#EDEDED !important;
        color:red;
        font-weight:bold;
    }
    
    margin:2px;
    border: solid 1px #EDEDED;
    border-radius: 5px;
    background: rgba(0, 220, 255, 0.86);

    .top-header {
        padding: 2px;
        background: #00c6ff;
        div.title{
            padding: 2px 4px;
            font-size: 14px; 
        }
        .button-groups{
            margin-bottom: 0px;
            display:inline-block;
            button{
                outline:0px;
                background: white;
                border-radius: 2px;
            }
        }
    }
    ol li{
        font-size: 14px;
        color: black;
    }
}
.plan-header-row{
    padding:8px 8px;
    background:#249fbb  ;
    .w3-col {
        padding:2px;
    }
    select {
        margin-bottom: 0px;
    }
    .workspace-label{
        margin:2px 2px;
        border-radius: 2px;
        font-size: 12px;
    }
    a.workspace-label,button.workspace-label{
        cursor: pointer;
        font-size: 1.1em; 
        padding:0.4em;
        white-space: pre-wrap;
    }
    .dropdown {
        background: white;
        border-radius: 2px; 
        .is-dropdown-submenu-parent{
            width:100%;
        }
    }
}


.position-left {
    width:740px;
    -webkit-transform: translateX(-740px);
    -ms-transform: translateX(-740px);
    transform: translateX(-740px);
}
.btn-default {
    padding: 0.5em 0.8em;
    background-color: #d4d4d4;
    color: #333;
    margin-left: 2px;
    margin-right: 2px;
}
.btn-default:hover, .btn-default:focus {
    background-color: #bdbdbd;
    color: #333;
}
.filter-menu{
    border: 1px solid #cacaca;
    background-color: #e5e5e5;
    color: #333;
    a.holder-menu {
        padding: 4px 2.5rem 5px 16px;
        line-height: 20px;
    }
}

.add-btn{
    min-width: 110px;
}

.add-label{
    margin: 5px;
}

.btn-fill {
    padding: 0.5em 0.8em;
    margin-left: 2px;
    margin-right: 2px;
}

.btn-fill.active{
    background: #11d7e0;
    color:black;
}

.new-submit-btn{
    margin-right: 2px;
    margin-left: 2px; 
    color: #ffffff;
    background-color: #34C145 !important;
    float: right;
    &:hover{
        background-color: #2ea319 !important;
        color: #ffffff !important;
    }
}
.new-save-btn{
    margin-right: 2px; 
    float: right;
}

.login-block {
    margin-top: 12vH;
    margin-left:120px;
}

.log-in-form {
    border: 1px solid #cacaca;
    padding: 1rem;
    border-radius: 3px;
}

.top-bar {
    background: #249fbb;
    color: white;
    width: 100%;
}

.section-header{
    padding:0.5em 0px
}

.top-bar  h4 {
    padding:0em 2em;
}

.top-bar-title {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 1rem;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
}

.top-bar-title > span {
    color: #28b777;
}

sticky-style-custom{
    margin-top: 0px;
    margin-bottom: 10px;
    background-color: #3b5998;
    z-index: 10000;
    height: 50px;
}

.tabs-title {
    background: #d1e3ff;
    color: white;
    height: 30px;
    font-size: 16px;
    font-weight: bold;
    border:1px solid #e6e6e6;
}

.footer {
    background: #333;
    color: #fff;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
}


#right-container {
    min-height: 550px;
}



.sidebar-wrapper {
    height: 150% !important;
    background: #fafafa;
    border-right: 1px solid #ececec;
    padding-top: 30px;
}

#sidebar-menu ul.w3-ul li {
    color: #333;
    font-size: 14px;
    border-bottom: 1px solid #ececec;
    display: block;
}

#sidebar-menu ul.w3-ul li a {
    display: block;
    text-decoration:none;
}

#sidebar-menu ul.w3-ul li:hover {
    background: #28b77a;
    color: #fff;
}

#sidebar-menu ul.w3-ul li.active {
    background: #28b77a;
    color: #fff;
}

.content-panel{
    margin-top: 10px;
    // border:1px solid #e6e6e6;
}

.package_detail{
    background-color: #808080;
    color: #fff;
    text-align: center;
    border: 1px solid #fff;
}
.package_value{
    background-color: #C0C0C0;
    text-align: center;
}
.edit_form{
    background-color: #eeeedd;
    padding: 2%;
    margin-bottom: 2%;
    box-shadow: 2px 2px 2px #888888;
}
.edit_head{
    color: #e60000;
}
.form_head{
    text-align: center;
}
td,th{
    text-align: left;
}

.w3-modal.open{
    display:block;
}

.w3-modal-content{
    width: 900px; 
}

@media screen and (max-width:900px) {
    .w3-modal-content{
        width:100%;
    }
}
.reveal-heading {
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
}


table.listing {
    font-size: 14px;
}

table.listing-overflow{
    display: block;
    overflow-x: auto;
    white-space: nowrap;   
}

h5{
    margin-bottom: 0px;
}

.day-column .meal-table {
    width: auto;
    display: inline-block;
} 

.day-column .day-meal-column{
    width:auto;
    min-width: 350px;
    margin: 10px;
}

.is-invalid-input:not(:focus){
    margin: 0 0 1rem;
}

.package-clientid .Select-control{
    // border-radius:0px;
    height:40px; 
}

.package-clientid .error-red{
    margin-left: 1rem;  
}

.pagination li a {
    float: left;
    padding: 4px 8px;
    text-decoration: none;
    display: block;
}

.pagination li{
    display:inline-block;
}

.pagination-left{
    margin-left: -40px;
}

.btn-body-vitals{
    margin-right: 5px;
}


.accordion-head:after{
    content: '\002B';
    color: #FFFFFF;
    font-weight: bold;
    float: right;
    margin-right: 5px;
    font-size: 15px!important;
}

.accordion-head.active:after {
    content: "\2212";
}

.w3-select{
    height:40px;
}

.btn-add-accordion{
    margin-top: 15px;
}

.symptoms-accordion{
    background-color: lightslategray;
    text-align: center;
    color: ghostwhite;
    padding: 2px;
    cursor: pointer;
}

.symptoms-accordion:hover,.symptoms-accordion.active{
    background-color:lightgrey;
    color:#1779ba;
}

.symptom.accordion-head.active:after,.symptom.accordion-head:hover:after{
    color:#1779ba;
}

.btn-add-accordion button{ 
    cursor: pointer;
}

.workspace-fld .columns{
    margin-top: 10px;
}

.drag-drop-container {
    position: relative;
}

.off-canvas-panel{
    z-index: 3;
    width: 55%;
}

.off-canvas-wrapper.diet-plan-workspace{
    height: 100%;
}

.settings-border{
    padding-bottom: 23px;
}

.button.expanded:focus{
    outline:0;
}

/*

input::-webkit-input-placeholder { 
    color:    #444444;
    opacity:  1;
}
input:-moz-placeholder { 
   color:    #444444;
   opacity:  1;
}
input::-moz-placeholder { 
   color:    #444444;
   opacity:  1;
}
input:-ms-input-placeholder { 
   color:    #444444;
   opacity:  1;
}
input::-ms-input-placeholder { 
   color:    #444444;
   opacity:  1;
}

input::placeholder { 
   color:    #444444;
   opacity:  1;
}

*/

.sort-indicator{
    width: 5%;
    padding-left: 5px;
}

.sortable-th .sort-row{
    display: inline-flex;
}

.sortable-th .ion-arrow-up-b:before, .sortable-th .ion-arrow-down-b:before{
    font-size: 12px;
    color: #808080;
    line-height: 0.75;
}

.font-wt{
    font-weight: 700;
    color: #000 !important;
}

.table .tb-drag-context{
    cursor: pointer;
    border: 1px solid #808080;
}

.workspace-btn, .workspace-btn:hover{
    color: #fff !important;
    background-color: #1779ba !important;
}


.workspace-label{
    display:inline-block;
    padding:.33333rem .5rem;
    border-radius:0;
    font-size:.8rem;
    line-height:1;
    white-space:nowrap;
    cursor:default;
}

.workspace-label.alert.font-wt{
    background: #e4d819;
}

.workspace-label.age.font-wt{
    background: #3adb76;
}

.workspace-label.category.font-wt{
    background: #ffae00;
}

.input-gender label.red,label.red{
    margin: 0 0 1rem;
    color: #cc4b37;
    font-size: .75rem;
    font-weight: 700;
}

.plan-overflow{
    height: 83%;
    overflow:scroll;
}

.plan-overflow table.plan-list{
    margin-top: 1rem;
}

.total-calories-filter.columns{
    padding-right: 0px;
    padding-left: 0px;
    margin-top: 0px;
}

.bottom-padding{
    margin-bottom: 1rem;
}

/* day planner scroll start */


table.meal-table thead th{
    font-weight: 400;
}

.day-column.vertical-scroll{
    overflow-y: hidden;
    // height: 86vh;
}
.day-column{
    width: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    overflow : scroll;
    border:solid 10px #dee0e1; 
} 
    
.meal-table tbody tr{
    background: none !important; 
    height: auto;
}

.meal-table tbody tr:last-child td{
    border-bottom: solid 1px #d5d5d5;
}

.day-meal-card ol li{
    text-align: left;
}

.day-column .day-meal-column{
    min-height: 300px;
}

/* day planner scroll end */

table .td-drag-context{
    display: initial;
    position: absolute;
    left: 10px;
}

table .td-drag-context-hide{
    display: none;
}

.table .ion-drag:before{
    padding-top: 9px;
}

ol.ol-food-items{
    margin-left: 0px;  
}


.params-view .reveal{
    width: 100%;
    overflow-y: scroll;
}

.report-table td, .report-table th{
    border: 1px solid #ddd;
    font-size:small;
}

.report-table tr th:nth-child(1), .report-table td:nth-child(1){
    position: sticky;
    left: 0;
    z-index: 15;
    background: #fff;
  }
  .report-table tr th:nth-child(2), .report-table td:nth-child(2){
    position: sticky;
    left: 98px;
    z-index: 15;
    background: #fff;
    border: 1px solid #ddd;
  }

.params-view .vital-params-report{
    overflow-x: auto;
    white-space: nowrap;
}

.report-file .close-button{
    position: static;
}

.report-file .ion-close:before{
    padding-top: 4px;
}

.lbl-report-file{
    width: auto;
    padding-left: 0px;    
}

.row.content-panel, hr{
    max-width:100%;
}

.param-category h4{
    margin: 5px 0;
}
.nutrify-url-list{
    white-space: nowrap !important; 
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:100%;
    padding-right:10px !important;
}
.nutrify-flex{
    display: flex;
    flex-direction: row;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);

    .modal-container {
        position: absolute;
        width: 60%;
        background: #ffffff;
        left: 25%;
        top: 30%;
        padding: 20px;
    }
}


.display-show{
    display: block;
    background: rgba(0, 0, 0, 0.8);
}

.display-none{
    display: none;
}
.display-block{
    display: block;
}

/* End of CSS for NutrifyMyDiet */
